<template>
    <div class="row table-sm mr-0 ml-0 p-0">
        <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
            <div
                class="
                    col-7
                    p-0
                    align-self-center
                    font-weight-bold
                    d-flex
                    align-items-center
                "
            >
                <h5 class="d-inline mr-2 font-weight-bold">
                    {{ $t("message.normaLaboratory") }}
                </h5>
                <crm-refresh @c-click="refresh()"></crm-refresh>
                <div class="text-center d-flex sorddata ml-3">
                    <el-input
                        size="mini"
                        placeholder="Найти"
                        prefix-icon="el-icon-search"
                        v-model="filterForm.search"
                        clearable
                    ></el-input>
                </div>
            </div>

            <div
                class="
                    col-5
                    align-items-center align-self-center
                    text-right
                    pr-0
                    d-flex
                    justify-content-end
                "
            >
                <el-button size="mini" @click="appModal = true">
                    <i class="el-icon-circle-plus-outline"></i>
                    {{ $t("message.create") }}
                </el-button>
                <export-excel
                    class="btn excel_btn"
                    :data="list"
                    @fetch="controlExcelData()"
                    worksheet="Норма Лаборатория"
                    name="Норма_Лаборатория.xls"
                >
                    <el-button size="mini">
                        <i class="el-icon-document-delete"></i> Excel
                    </el-button>
                </export-excel>
                <crm-column-settings
                    :columns="columns"
                    :modelName="'costs'"
                ></crm-column-settings>
            </div>
        </div>

        <table class="table table-bordered table-hover">
            <thead>
                <tr>
                    <th class="w50p" v-if="columns.id.show">
                        {{ columns.id.title }}
                    </th>

                    <th v-if="columns.productName.show">
                        {{ columns.productName.title }}
                    </th>

                    <th v-if="columns.dataContract.show">
                        {{ columns.dataContract.title }}
                    </th>

                    <th v-if="columns.data.show">
                        {{ columns.data.title }}
                    </th>

                    <th v-if="columns.settings.show">
                        {{ columns.settings.title }}
                    </th>
                </tr>

                <tr class="filter_sorche">
                    <th v-if="columns.id.show">
                        <el-input
                            clearable
                            size="mini"
                            v-model="filterForm.id"
                            :placeholder="columns.id.title"
                            class="id_input"
                        ></el-input>
                    </th>

                    <th v-if="columns.productName.show">
                        <el-input
                            clearable
                            size="mini"
                            v-model="filterForm.productName"
                            :placeholder="columns.productName.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.dataContract.show">
                        <el-date-picker
                            :placeholder="columns.dataContract.title"
                            v-model="filterForm.dataContract"
                            size="mini"
                        >
                        </el-date-picker>
                    </th>

                    <th v-if="columns.data.show">
                        <el-date-picker
                            :placeholder="columns.data.title"
                            v-model="filterForm.data"
                            size="mini"
                        >
                        </el-date-picker>
                    </th>

                    <th class="settinW" v-if="columns.settings.show"></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="user in list" :key="user.id" class="cursor-pointer">
                    <td v-if="columns.id.show">{{ user.id }}</td>

                    <td v-if="columns.productName.show">
                        {{ user.productName }}
                    </td>

                    <td v-if="columns.dataContract.show">
                        {{ user.dataContract }}
                    </td>

                    <td v-if="columns.data.show">{{ user.data }}</td>

                    <td v-if="columns.settings.show" class="settings-td">
                        <el-dropdown
                            :hide-on-click="false"
                            class="table-bottons-right"
                        >
                            <span class="el-dropdown-link">
                                <i
                                    class="
                                        el-icon-arrow-down el-icon-more-outline
                                    "
                                ></i>
                            </span>
                            <el-dropdown-menu
                                slot="dropdown"
                                class="dropdown-menumy-style"
                            >
                                <el-dropdown-item icon="el-icon-edit">
                                    Изменить
                                </el-dropdown-item>
                                <el-dropdown-item icon="el-icon-delete">
                                    Удалить
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </td>
                </tr>
            </transition-group>
        </table>

        <el-drawer
            class="right-modal"
            :with-header="false"
            :visible.sync="appModal"
            size="80%"
        >
            <CrmCreate />
        </el-drawer>
    </div>
</template>

<script>
//   import {
//     mapGetters,
//     mapActions
//   } from "vuex";
//   import CrmCreate from "./components/crm-create";
// //   import CrmUpdate from "./components/crm-update";
//   import list from "@/utils/mixins/list";
//   import drawer from "@/utils/mixins/drawer";
//   export default {
//     mixins: [list, drawer],
//     name: "normLaboratory",
//     components: {
//       CrmCreate,
//     //   CrmUpdate
//     },
//     data() {
//       return {
//         loadingData: false,
//         selectedModel: {},
//         drawer: {
//           create: {
//             name: "create",
//             status: false,
//             component: 'componentDrawerCreate'
//           },
//           update: {
//             name: "update",
//             status: false,
//             component: 'componentDrawerUpdate'
//           }
//         }
//       }
//     },
//     computed: {
//       ...mapGetters({
//         list: 'normLaboratory/list',
//         columns: "normLaboratory/columns",
//         pagination: "normLaboratory/pagination",
//         filter: "normLaboratory/filter",
//         sort: "normLaboratory/sort",
//         cities: "cities/list"
//       }),
//       actions: function () {
//         return ['edit', 'delete'];
//       }
//     },
//     async mounted() {
//       if (this.cities && this.cities.length === 0)
//         await this.loadCities();
//     },
//     methods: {
//       ...mapActions({
//         updateList: 'normLaboratory/index',
//         updateSort: "normLaboratory/updateSort",
//         updateFilter: "normLaboratory/updateFilter",
//         updateColumn: "normLaboratory/updateColumn",
//         updatePagination: "normLaboratory/updatePagination",
//         editModel: 'normLaboratory/show',
//         empty: 'normLaboratory/empty',
//         delete: 'normLaboratory/destroy',
//         refreshData: 'normLaboratory/refreshData',
//         loadCities: "cities/index",
//       }),
//       fetchData() {
//         const query = {
//           ...this.filter,
//           ...this.pagination,
//           ...this.sort
//         };
//         if (!this.loadingData) {
//           this.loadingData = true;
//           this.updateList(query).then(res => {
//             this.loadingData = false
//           }).catch(err => {
//             this.loadingData = false
//           });
//         }
//       },
//       refresh() {
//         this.refreshData()
//           .then(res => {
//             this.filterForm = JSON.parse(JSON.stringify(this.filter));
//           })
//           .catch(err => {

//           })
//       },
//       edit(model) {
//         this.selectedModel = model;
//         this.drawer.update.status = true;
//       },
//       async show(model) {
//         await this.showModel(model.id)
//           .then(res => {
//             this.drawerShow = true;
//           })
//           .catch(err => {

//           })
//       },
//       destroy(model) {
//         this.delete(model.id)
//           .then(res => {
//             this.$alert(res);
//             this.fetchData()
//           })
//           .catch(err => {
//             console.log(err)
//           })
//       },
//       emptyModel() {
//         this.empty()
//       },
//     }
//   };




// @ is an ali   as to /src
import CrmCreate from "./components/crm-create";
export default {
    name: "normaLaboratory",
    components: {
        CrmCreate,
    },

    data() {
        return {
            appModal: false,
            sarcheBlocks: "",
            filterForm: {
                id: "",
                productName: "",
                providersUser: "",
                telephone: "",
                dataContract: "",
                data: "",
            },
            list: [
                {
                    id: "1",
                    productName: "Choy",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                productName: {
                    show: true,
                    title: "Наименование",
                    sortable: true,
                    column: "productName",
                },

                dataContract: {
                    show: true,
                    title: "Дата создания ",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: true,
                    title: "Изменено ",
                    sortable: true,
                    column: "data",
                },

                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
};
</script>

